const config = {
    API: {
        endpoints: [
            {
                name: 'bxAPI',
                endpoint: 'https://u9tf66y4sa.execute-api.us-east-1.amazonaws.com/prod/',
                region: 'us-east-1',
                service: 'lambda',
            },
        ],
    }
};

export default config;