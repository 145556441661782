import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { getExperiences } from '../../api/APIs';
import Experience from '../../interfaces/Experience';
import './Timeline.css';

export default function Timeline() {
    const [experiences, setExperiences] = useState<Experience[]>([]);

    useEffect(() => {
        getExperiences().then((resp) => {
            resp.sort((a: Experience, b: Experience) => b.Id - a.Id);
            setExperiences(resp);
        });
    }, []);

    return (
        <Container>
            <ul className="timeline mt-5">
                {experiences.map((exp) => {
                    return (
                        <li>
                            <div className={exp.Type === 'Work' ? "direction-r" : "direction-l"}>
                                <div className="flag-wrapper">
                                    <span className="flag">{exp.Title}</span>
                                    <span className="time-wrapper"><span className="time">{exp.TimeRange}</span></span>
                                </div>
                                <div className="desc"><div dangerouslySetInnerHTML={{ __html:  exp.Content}}></div></div>
                            </div>
                        </li>
                    );
                })}
            </ul>
        </Container>
    );
}