import React from 'react';
import './App.css';
import { Router, Switch, Route } from 'react-router-dom';
import NavBar from '../components/shared/navbar/NavBar';
import AboutMe from '../components/aboutMe/AboutMe';
import Timeline from '../components/timeline/Timeline';
import ProjectDashboard from '../components/projects/ProjectDashboard';
import history from './History';

function App() {
  return (
    <React.Fragment>
      <NavBar />
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={Timeline}></Route>
          <Route exact path="/about" component={AboutMe}></Route>
          <Route exact path="/timeline" component={Timeline}></Route>
          <Route exact path="/projects" component={ProjectDashboard}></Route>
        </Switch>
      </Router>
    </React.Fragment>
  );
}

export default App;
