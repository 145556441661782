import React from 'react';
import './NavBar.css';
import { Container, Nav, Navbar } from 'react-bootstrap';
import history from '../../../app/History';

export default function NavBar() {
    return (
        <Navbar bg="light" expand="lg" sticky="top">
            <Container>
                <div className="bx-brand-container py-2 px-3" onClick={() => history.push('/')}>
                    <div className="bx-brand-text align-middle my-2">
                        Binwei Xu
                    </div>
                </div>
                <Navbar.Toggle className="ml-auto" aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto">
                        {/* <Nav.Link href="/about" className="hoverable text-right">About Me</Nav.Link> */}
                        <Nav.Link href="/timeline" className="hoverable text-right">Timeline</Nav.Link>
                        <Nav.Link href="/projects" className="hoverable ml-4 text-right">Projects</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
