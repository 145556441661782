import React from 'react';
import cx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import TextInfoContent from '@mui-treasury/components/content/textInfo';
import { useBlogTextInfoContentStyles } from '@mui-treasury/styles/textInfoContent/blog';
import { useOverShadowStyles } from '@mui-treasury/styles/shadow/over';

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
    root: {
        margin: 'auto',
        borderRadius: spacing(2), // 16px
        transition: '0.3s',
        boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',
        position: 'relative',
        maxWidth: 500,
        marginLeft: 'auto',
        overflow: 'initial',
        background: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: spacing(2),
        [breakpoints.up('md')]: {
        flexDirection: 'row',
        paddingTop: spacing(2),
        backgroundImage: props => props.rootBackgroundImage,
        backgroundSize: '100% 100%',
        },
    },
    media: {
        width: '88%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: spacing(-3),
        height: 0,
        paddingBottom: '48%',
        borderRadius: spacing(2),
        backgroundColor: '#fff',
        position: 'relative',
        [breakpoints.up('md')]: {
        width: '100%',
        marginLeft: spacing(-3),
        marginTop: 0,
        transform: 'translateX(-8px)',
        },
        '&:after': {
        content: '" "',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: spacing(2), // 16
        boxShadow: '4px 4px 20px rgba(34, 35, 58, 0.6)',
        opacity: 0.2,
        },
    },
    content: {
        padding: 24,
    },
    cta: {
        marginTop: 24,
        textTransform: 'initial',
    },
}));

const rootBgImage = {
    primary: "url('https://binweixucom.s3.amazonaws.com/public/primary-card-bg-light.png')",
    sunset: "url('https://binweixucom.s3.amazonaws.com/public/sunset-card-bg-light.png')",
    grayscale: "url('https://binweixucom.s3.amazonaws.com/public/grayscale-card-bg-light.png')",
    jungle: "url('https://binweixucom.s3.amazonaws.com/public/jungle-card-bg-light.png')",
}

const btnColor = {
    primary: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
    sunset: 'linear-gradient(147deg, #fe8a39 0%, #fd3838 74%)',
    grayscale: 'linear-gradient(69deg, #D4AF37 5%, #C5A028 74%)',
    jungle: 'linear-gradient(147deg, #21811f 0%, #60a45f 74%)',
}

const btnShadow = {
    primary: '0px 4px 32px rgba(54, 202, 252, 0.4)',
    sunset: '0px 4px 32px rgba(252, 56, 56, 0.4)',
    grayscale: '0px 4px 32px #c5a028c9',
    jungle: '0px 4px 32px rgba(27, 106, 16, 0.6)',
}

export default function ProjectCard(props) {
    const { project } = props;
    const styles = useStyles({ rootBackgroundImage: rootBgImage[project.Color] });
    const {
        button: buttonStyles,
        ...contentStyles
    } = useBlogTextInfoContentStyles();
    const shadowStyles = useOverShadowStyles();

    return (
        <Card className={cx(styles.root, shadowStyles.root)}>
            <CardMedia
                className={styles.media}
                image={project.Image}
            />
            <CardContent>
                <TextInfoContent
                classes={contentStyles}
                overline={project.TechStack}
                heading={project.Title}
                body={project.Description}
                />
                <Button
                    className={buttonStyles}
                    style={{backgroundImage: `${btnColor[project.Color]}`, boxShadow: `${btnShadow[project.Color]}`}}
                    onClick={() => window.open(project.Href, "_blank")}
                >
                    Check it out
                </Button>
            </CardContent>
        </Card>
    );
}