import { API } from 'aws-amplify';
import Experience from '../interfaces/Experience';
import Project from '../interfaces/Project';

export const getExperiences = async () => {
    return API.get('bxAPI', 'table/experiences', {})
        .then((resp) => resp.map((exp: object) => exp as Experience));
};

export const getProjects = async () => {
    return API.get('bxAPI', 'table/projects', {})
        .then((resp) => resp.map((exp: object) => exp as Project));
};
