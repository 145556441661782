import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { getProjects } from '../../api/APIs';
import Project from '../../interfaces/Project';
import ProjectCard from './ProjectCard.js';

export default function ProjectDashboard() {
    const [projects, setProjects] = useState<Project[]>([]);

    useEffect(() => {
        getProjects().then((resp) => {
            resp.sort((a: Project, b: Project) => b.Id - a.Id);
            setProjects(resp);
        });
    }, []);

    return (
        <Container className="mt-5">
            <Row>
                {projects.map((project) => {
                    return (
                        <Col lg='6' xs='12' className='mt-5'>
                            <ProjectCard project={project} />
                        </Col>
                    );
                })}
            </Row>
        </Container>
    );
}